<template>
    <div>
        <topBarY />
        <div class="bg">
            <img src="../../../../assets/common/pic/bg.jpg" alt="">
        </div>
        <div class="switchBar">
            <div class="tab cabinet" :class="{ active: switchIndex == 1 }">高带宽无线传输网桥</div>
        </div>
        <div class="line"></div>
        <div class="switchContiner">
            <div class="continer cabinetContiner">
                <div class="continer">
                    <div class="introduce">
                        <span class="tit">
                            高带宽无线传输网桥
                        </span>
                        <br>
                        <br>
                        <span class="txt">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-780N系列是宁讯信息科技推出的对高速无线传输、大范围信号覆盖、<br>
                            远距离无线互联等需求推出的可调功率户外无线传输覆盖网桥单元。采用了可<br>
                            选双频多模802.1la/b/g/n MiMO 高速芯片组及内置板状天(5.8G17dBi/23d<br>
                            Bi)和外接天线(2.4G/5.8G双天线接口)设计(不同型号规格定制)，内置接地防<br>
                            雷模块，同时可提供无线信号覆盖与网桥互联的完美结合。可适用于户外环<br>
                            境厂矿、森林等领域长距离视频监控速输等场景应用。
                        </span>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                    <img src="../../../../assets/support/wirelessTransmissionBridge.png" alt="">
                </div>
            </div>
        </div>
        <baseBar />
    </div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
    name: 'Product',
    components: {
        topBarY,
        baseBar
    },
    data() {
        return {
            switchIndex: 1
        }
    },
    methods: {
        switchClick(index) {
            this.switchIndex = index
        }
    },
    created() {
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                    e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        });
    }
}
</script>

<style scoped>
.bg {
    position: relative;
    width: 1920px;
    height: 480px;
    overflow: hidden;
}

.bg img {
    position: absolute;
    top: -100px;
    width: 1920px;
}

.switchBar {
    width: 1920px;
    height: 70px;
    background-color: #fff;
    text-align: center;
}

.switchBar .tab {
    display: inline-block;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 190px;
    height: 40px;
    background-color: #c3a35d;
    color: #f5f5f5;
    font-family: 'Microsoft Yahei';
    font-size: 16px;
    line-height: 40px;
}

.line {
    margin: 0 auto;
    width: 1400px;
    height: 1px;
    background-color: #c3a35d;
}

.switchContiner {
    width: 1920px;
    padding: 20px;
    background-color: #f7f7f7;
}

/* 智能保管柜 */
.switchContiner .cabinetContiner {
    margin: 0 auto;
    width: 1400px;
    height: 500px;
    border-radius: 5px;
    background-color: #fff;
}

.switchContiner .cabinetContiner .continer {
    position: relative;
    margin: 0 auto;
    padding-top: 40px;
    width: 1000px;
    height: 200px;
}

.switchContiner .cabinetContiner .continer .tit {
    font-size: 18px;
    margin-bottom: 20px;
}

.switchContiner .cabinetContiner .continer .txt {
    color: #999;
    font-size: 14px;
    line-height: 30px;
}

.switchContiner .cabinetContiner img {
    position: absolute;
    top: 70px;
    right: 0;
    width: 400px;
}
</style>
